.overlay-end {
  position: absolute;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  z-index: 4;
  pointer-events: none;
}

.overlay-end button {
  pointer-events: auto;
}

.overlay-end button:hover {
    cursor: pointer;
}

.overlay-end-container {
  display: flex;
  width:48%
}

.social-media-container {
  display: flex;
  justify-content: space-around;
  width: 47%;
  align-items: flex-end;
}

.social-media-links{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    /* margin-bottom: 5%; */
    width: 74%;
    height: 19%;
    margin-bottom: 12%;
    margin-right: 1%;
}

.icon svg{
    width: 75%;
    height: 50%
}

.icon a {
    width: 100%;
    height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    pointer-events: auto;
}

.icon button {
  width: 100%;
    height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    pointer-events: auto;
    background: none;
    border: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-size: 1.0em;
    font-weight: 600;
}

.cartouche-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.icon span{
    font-size: 1.5em;
    font-weight: 600;
}

.icon{
    width:20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
}


.margin-zero-auto {
  margin: 0 auto;
}

.no-select-text {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fadeIn {
  animation: fadeInAnimation 0.7s forwards;
}

@keyframes fadeInAnimation {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
